import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tracking from 'services/tracking';
import { useRoute } from '@react-navigation/native';

import TaxStatementOrPensionStatementIsNotComplete from './TaxStatementOrPensionStatementIsNotComplete';
import GenericError from './GenericError';
import ErrorButton from './ErrorButton';

const getPayoutType = (payoutType, i18n) => {
  switch (payoutType) {
    case 'LivsvarigUdbetaling':
      return i18n.t('dashboardErrors|lifelongPayout');
    case 'OphørendeUdbetaling':
      return i18n.t('dashboardErrors|expiringPayout');
    default:
      return payoutType;
  }
};

const ErrorCard = ({
  pensionStatus,
  i18n,
  pensionDate,
  taxDate,
  nakedDashboardErrors,
  failedCoverageOverview,
}) => {
  const route = useRoute();

  useEffect(() => {
    Tracking.trackEvent(
      {
        area: route.name,
        action: 'ErrorCard',
      },
      { errorId: nakedDashboardErrors.errors[0].errorId }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nakedDashboardErrors.errors[0].errorId]);

  const { someCompaniesDidNotDeliverPensionsInfoData } = pensionStatus;

  if (nakedDashboardErrors) {
    const { errors } = nakedDashboardErrors;

    if (errors && errors.length > 0) {
      const {
        errorId,
        isTaxDataOutdated,
        isPensionsInfoDataOutdated,
        isLastTaxDataUpdateFailed,
        isLastPensionInfoUpdateFailed,
      } = errors[0];

      switch (errorId) {
        case 'Sc100':
        case 'Sc101':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|pensionCalculation')}
              type="info"
              description={
                errorId === 'Sc100'
                  ? i18n.t('dashboardErrors|errorScenarioSc100')
                  : i18n.t('dashboardErrors|errorScenarioSc101')
              }
              buttonComponent={
                <>
                  <ErrorButton
                    name="UseExistingDataButton"
                    text={i18n.t('dashboardErrors|seePayoutOverview')}
                    nakedDashboardErrors={nakedDashboardErrors}
                    errorId={errorId}
                  />
                </>
              }
              errorId={errorId}
            />
          );
        case 'Sc200':
        case 'Sc300':
        case 'Sc301':
        case 'Sc302':
        case 'Sc303':
        case 'Sc304':
        case 'Sc305':
        case 'Sc306':
        case 'Sc307':
        case 'Sc308':
        case 'Sc309':
          return (
            <TaxStatementOrPensionStatementIsNotComplete
              pensionStatus={pensionStatus}
              pensionDate={pensionDate}
              taxDate={taxDate}
              errorId={errorId}
              nakedDashboardErrors={nakedDashboardErrors}
            />
          );
        case 'Sc310': {
          let description;
          let errorButtonName;

          switch (true) {
            case isLastTaxDataUpdateFailed && isLastPensionInfoUpdateFailed:
              description = i18n.t(
                'dashboardErrors|errorScenarioSc310LatestTaxAndPensionFetchFailed',
                {
                  pensionDate,
                  taxDate,
                }
              );
              errorButtonName = 'UpdatePensionAndTaxButton';
              break;
            case isLastTaxDataUpdateFailed:
              description = i18n.t('dashboardErrors|errorScenarioSc310LatestTaxFetchFailed', {
                date: taxDate,
              });
              errorButtonName = 'UpdateTaxDataButton';
              break;
            case isLastPensionInfoUpdateFailed:
              errorButtonName = 'UpdatePensionDataButton';
              description = i18n.t('dashboardErrors|errorScenarioSc310LatestPensionFetchFailed', {
                date: pensionDate,
              });
              break;
            default:
          }

          return (
            <GenericError
              title={i18n.t('dashboardErrors|latestUpdateFailed')}
              description={description}
              buttonComponent={
                <>
                  <ErrorButton
                    name={errorButtonName}
                    text={i18n.t('dashboardErrors|getNewInformation')}
                  />
                  <ErrorButton
                    name="UseExistingDataButton"
                    text={i18n.t('dashboardErrors|useExistingInformation')}
                    nakedDashboardErrors={nakedDashboardErrors}
                    errorId={errorId}
                    type="secondary"
                  />
                </>
              }
              errorId={errorId}
            />
          );
        }
        case 'Sc400':
        case 'Sc401':
        case 'Sc411':
        case 'Sc413':
        case 'Sc414': {
          const description =
            errorId === 'Sc414'
              ? i18n.t('dashboardErrors|errorScenarioSc414')
              : i18n.t('dashboardErrors|errorScenarioSc400ToSc413');
          const errorButtonName =
            errorId === 'Sc414' ? 'AdjustIncomeButton' : 'UpdatePensionDataButton';

          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={description}
              buttonComponent={
                <>
                  <ErrorButton name={errorButtonName} />
                  <ErrorButton name="ContactUsButton" type="secondary" />
                </>
              }
              errorId={errorId}
            />
          );
        }
        case 'Sc410': {
          const description = failedCoverageOverview?.map((coverage) => coverage?.description);
          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={description}
              buttonComponent={<ErrorButton name="ContactUsButton" />}
              errorId={errorId}
            />
          );
        }
        case 'Sc403': {
          const listItems = failedCoverageOverview?.map((coverage) =>
            i18n.t('dashboardErrors|errorScenarioSc403ListItems', {
              type: getPayoutType(coverage?.payoutType, i18n),
              name: coverage?.name,
              description: coverage?.description,
            })
          );

          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={i18n.t('dashboardErrors|errorScenarioSc403')}
              buttonComponent={<ErrorButton name="ContactUsButton" />}
              errorId={errorId}
              listItems={listItems}
            />
          );
        }
        case 'Sc404':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={i18n.t('dashboardErrors|errorScenarioSc404')}
              buttonComponent={<ErrorButton name="ContactUsButton" />}
              errorId={errorId}
            />
          );
        case 'Sc405':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|noRecommendation')}
              description={i18n.t('dashboardErrors|errorScenarioSc405')}
              buttonComponent={<ErrorButton name="ContactUsButton" />}
              errorId={errorId}
            />
          );
        case 'Sc406':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|proposedRetirementAgeCannotBeDisplayed')}
              description={i18n.t('dashboardErrors|errorScenarioSc406')}
              subtitle={
                someCompaniesDidNotDeliverPensionsInfoData
                  ? i18n.t('dashboardErrors|someCompaniesDidNotDeliverPensionInformation', {
                      date: pensionDate,
                    })
                  : i18n.t('dashboardErrors|allInformationIsDelivered', { date: pensionDate })
              }
              buttonComponent={
                <ErrorButton
                  name="UseExistingDataButton"
                  text={i18n.t('dashboardErrors|seePayoutOverview')}
                  nakedDashboardErrors={nakedDashboardErrors}
                  errorId={errorId}
                />
              }
              errorId={errorId}
            />
          );
        case 'Sc407': {
          let description;
          let errorButtonName;

          switch (true) {
            case isTaxDataOutdated && isPensionsInfoDataOutdated:
              description = i18n.t('dashboardErrors|errorScenarioSc407ArePensionAndTaxOutdated', {
                pensionDate,
                taxDate,
              });
              errorButtonName = 'UpdatePensionAndTaxButton';
              break;
            case isTaxDataOutdated:
              description = i18n.t('dashboardErrors|errorScenarioSc407IsTaxOutdated', { taxDate });
              errorButtonName = 'UpdateTaxDataButton';
              break;
            case isPensionsInfoDataOutdated:
              errorButtonName = 'UpdatePensionDataButton';
              description = i18n.t('dashboardErrors|errorScenarioSc407IsPensionOutdated', {
                pensionDate,
              });
              break;
            default:
          }

          return (
            <GenericError
              title={i18n.t('dashboardErrors|informationIsOutdated')}
              description={description}
              buttonComponent={
                <>
                  <ErrorButton
                    name={errorButtonName}
                    text={i18n.t('dashboardErrors|getNewInformation')}
                  />
                  <ErrorButton
                    name="UseExistingDataButton"
                    text={i18n.t('dashboardErrors|useExistingInformation')}
                    nakedDashboardErrors={nakedDashboardErrors}
                    errorId={errorId}
                    type="secondary"
                  />
                </>
              }
              errorId={errorId}
            />
          );
        }
        case 'Sc408':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|isYourPresentIncomeCorrect')}
              description={i18n.t('dashboardErrors|errorScenarioSc408')}
              buttonComponent={<ErrorButton name="AdjustIncomeButton" />}
              errorId={errorId}
            />
          );
        case 'Sc409':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|isYourPresentIncomeCorrect')}
              description={i18n.t('dashboardErrors|errorScenarioSc409')}
              buttonComponent={
                <>
                  <ErrorButton
                    name="UseExistingDataButton"
                    text={i18n.t('dashboardErrors|seePayoutOverview')}
                    nakedDashboardErrors={nakedDashboardErrors}
                    errorId={errorId}
                  />
                  <ErrorButton name="AdjustIncomeButton" type="secondary" />
                </>
              }
              errorId={errorId}
            />
          );
        case 'Sc412':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|noRecommendation')}
              description={i18n.t('dashboardErrors|errorScenarioSc412')}
              buttonComponent={
                <>
                  <ErrorButton name="UpdateTaxDataButton" />
                  <ErrorButton name="ContactUsButton" type="secondary" />
                </>
              }
              errorId={errorId}
            />
          );
        case 'Sc415':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|informationIsOutdatedAndPartlyDelivered')}
              description={i18n.t('dashboardErrors|errorScenarioSc415')}
              pensionStatus={pensionStatus}
              nakedDashboardErrors={nakedDashboardErrors}
              i18n={i18n}
              buttonComponent={
                <>
                  <ErrorButton name="UpdatePensionAndTaxButton" />
                  <ErrorButton
                    name="UseExistingDataButton"
                    text={i18n.t('dashboardErrors|useExistingInformation')}
                    nakedDashboardErrors={nakedDashboardErrors}
                    errorId={errorId}
                    type="secondary"
                  />
                </>
              }
              errorId={errorId}
            />
          );
        case 'Sc416':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|noRecommendation')}
              description={i18n.t('dashboardErrors|errorScenarioSc416')}
              buttonComponent={
                <ErrorButton
                  name="UseExistingDataButton"
                  text={i18n.t('dashboardErrors|seePayoutOverview')}
                  nakedDashboardErrors={nakedDashboardErrors}
                  errorId={errorId}
                />
              }
              errorId={errorId}
            />
          );
        case 'Sc417':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|noRecommendation')}
              description={i18n.t('dashboardErrors|errorScenarioSc417')}
              buttonComponent={
                <ErrorButton
                  name="UseExistingDataButton"
                  text={i18n.t('dashboardErrors|seePayoutOverview')}
                  nakedDashboardErrors={nakedDashboardErrors}
                  errorId={errorId}
                />
              }
              errorId={errorId}
            />
          );
        case 'Sc418':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={i18n.t('dashboardErrors|errorScenarioSc418')}
              buttonComponent={
                <ErrorButton
                  name="UseExistingDataButton"
                  text={i18n.t('dashboardErrors|seePayoutOverview')}
                  nakedDashboardErrors={nakedDashboardErrors}
                  errorId={errorId}
                />
              }
              errorId={errorId}
            />
          );
        case 'Sc420':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={i18n.t('dashboardErrors|errorScenarioSc420')}
              subtitle={i18n.t('dashboardErrors|errorScenarioSc420Reason')}
              buttonComponent={<ErrorButton name="ContactUsButton" />}
              errorId={errorId}
            />
          );
        case 'Sc421':
          return (
            <GenericError
              title={i18n.t('dashboardErrors|missingChildrenInformation')}
              description={i18n.t('dashboardErrors|errorScenarioSc421')}
              nakedDashboardErrors={nakedDashboardErrors}
              i18n={i18n}
              buttonComponent={
                <>
                  <ErrorButton name="UpdateChildrenInformation" />
                  <ErrorButton
                    name="UseExistingDataButton"
                    type="secondary"
                    text={i18n.t('common|skip')}
                    nakedDashboardErrors={nakedDashboardErrors}
                    errorId={errorId}
                  />
                </>
              }
              errorId={errorId}
            />
          );
        default:
          return (
            <GenericError
              title={i18n.t('dashboardErrors|weAreSorry')}
              description={i18n.t('dashboardErrors|errorScenarioUnknown')}
              subtitle={
                someCompaniesDidNotDeliverPensionsInfoData
                  ? i18n.t('dashboardErrors|someCompaniesDidNotDeliverPensionInformation', {
                      date: pensionDate,
                    })
                  : i18n.t('dashboardErrors|allInformationIsDelivered', { date: pensionDate })
              }
              buttonComponent={
                <>
                  <ErrorButton name="UpdatePensionAndTaxButton" />
                  <ErrorButton
                    name="UseExistingDataButton"
                    text={i18n.t('dashboardErrors|useOldInformation')}
                    type="secondary"
                    nakedDashboardErrors={nakedDashboardErrors}
                  />
                </>
              }
              errorId={errorId}
            />
          );
      }
    }
  }

  return null;
};

ErrorCard.propTypes = {
  tax: PropTypes.shape({
    hasData: PropTypes.bool,
    hasDisposableIncome: PropTypes.bool,
  }),
  pensionStatus: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  pensionDate: PropTypes.string,
  taxDate: PropTypes.string,
  nakedDashboardErrors: PropTypes.object,
  failedCoverageOverview: PropTypes.array,
};

ErrorCard.defaultProps = {
  tax: {},
  pensionDate: undefined,
  taxDate: undefined,
  nakedDashboardErrors: undefined,
  failedCoverageOverview: [],
};

export default ErrorCard;
