import React, { useEffect, useRef, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import PropTypes from 'prop-types';
import FlexPage from '@sp/ui/pages/FlexPage';
import WebView from '@sp/ui/base/WebView';
import { SafeAreaWrapper } from '@sp/ui/pages/BasePage';
import { isAndroid } from 'helpers/platform';

const Signing = ({ url, onCompleted, onCancelled, i18n }) => {
  const route = useRoute();
  const { cancelled, completed, signingId } = route.params ?? {};
  const webViewRef = useRef(null);
  const [alreadySigned, setAlreadySigned] = useState(false);

  useEffect(() => {
    if (completed && onCompleted) {
      onCompleted(signingId);
    } else if (cancelled && onCancelled) {
      onCancelled();
    }
  }, [completed, cancelled, signingId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SafeAreaWrapper>
      <FlexPage fullWidth title={i18n.t('Signering')} testID="signingScreen">
        {url && (
          <WebView
            ref={webViewRef}
            avoidKeyboard={isAndroid}
            keyboardAvoidBehaviour={isAndroid ? 'position' : 'margin'}
            source={{
              uri: url,
            }}
            startInLoadingState
            onNavigationStateChange={(navState) => {
              // deeplink from MitID to android doesn't work
              // - so we listen to the redirect url in the webview.
              if (isAndroid) {
                if (navState.url.includes('completed=true') && onCompleted) {
                  // get signingID from url if present
                  const navStateSigningId = navState.url?.split('signingId=')[1]?.split('&')[0];
                  webViewRef.current.stopLoading();
                  if (!alreadySigned) {
                    setAlreadySigned(true);
                    onCompleted(navStateSigningId);
                  }
                } else if (navState.url.includes('cancelled=true') && onCancelled) {
                  webViewRef.current.stopLoading();
                  onCancelled();
                }
              }
            }}
          />
        )}
      </FlexPage>
    </SafeAreaWrapper>
  );
};

Signing.propTypes = {
  url: PropTypes.string,
  onCompleted: PropTypes.func,
  onCancelled: PropTypes.func,
  i18n: PropTypes.object.isRequired,
};

Signing.defaultProps = {
  url: undefined,
  onCompleted: undefined,
  onCancelled: undefined,
};

export default Signing;
