export const errorScenarios = (dataStatus, error) => {
  const triedToGetPensionInfoWithoutSuccess =
    !dataStatus.pensionsInfoDataHasBeenFetched && dataStatus.pensionsInfoDataUpdateDate;

  const triedToGetTaxInfoWithoutSuccess =
    !dataStatus.taxDataHasBeenFetched && dataStatus.taxDataUpdateDate;

  const skippedPension =
    !dataStatus.pensionsInfoDataHasBeenFetched && !dataStatus.pensionsInfoDataUpdateDate;

  const skippedTax = !dataStatus.taxDataHasBeenFetched && !dataStatus.taxDataUpdateDate;

  const errorCombos = [
    {
      // pension data is missing - pension and tax is not outdated.
      errorId: 'Sc200',
      errorPriority: 'partlyDelivered',
      errorCategory: 'calculation',
      hasError:
        dataStatus.taxDataHasBeenFetched &&
        dataStatus.pensionsInfoDataHasBeenFetched &&
        dataStatus.someCompaniesDidNotDeliverPensionsInfoData &&
        !dataStatus.isTaxDataOutdated &&
        !dataStatus.isPensionsInfoDataOutdated,
    },
    {
      // pension skipped, tax fetched
      errorId: 'Sc300',
      errorPriority: 'lackOfData',
      errorCategory: 'calculation',
      hasError: dataStatus.taxDataHasBeenFetched && dataStatus.taxDataUpdateDate && skippedPension,
    },
    {
      // pension failed, tax fetched
      errorId: 'Sc301',
      errorPriority: 'lackOfData',
      errorCategory: 'calculation',
      hasError: dataStatus.taxDataHasBeenFetched && triedToGetPensionInfoWithoutSuccess,
    },
    {
      // tax skipped, pension fetched
      errorId: 'Sc302',
      errorPriority: 'lackOfDataAndTax',
      errorCategory: 'calculation',
      hasError:
        skippedTax &&
        dataStatus.pensionsInfoDataHasBeenFetched &&
        dataStatus.pensionsInfoDataUpdateDate &&
        !dataStatus.someCompaniesDidNotDeliverPensionsInfoData,
    },
    {
      // tax skipped, pension fetched with data missing
      errorId: 'Sc303',
      errorPriority: 'lackOfDataAndTax',
      errorCategory: 'calculation',
      hasError:
        skippedTax &&
        dataStatus.pensionsInfoDataHasBeenFetched &&
        dataStatus.someCompaniesDidNotDeliverPensionsInfoData,
    },
    {
      // everything skipped (tax skipped, pension skipped)
      errorId: 'Sc304',
      errorPriority: 'lackOfData',
      errorCategory: 'calculation',
      hasError: skippedPension && skippedTax,
    },
    {
      // tax skipped, pension failed
      errorId: 'Sc305',
      errorPriority: 'lackOfData',
      errorCategory: 'calculation',
      hasError: skippedTax && triedToGetPensionInfoWithoutSuccess,
    },
    {
      // tax failed, pension fetched
      errorId: 'Sc306',
      errorPriority: 'lackOfDataAndTax',
      errorCategory: 'calculation',
      hasError:
        triedToGetTaxInfoWithoutSuccess &&
        dataStatus.pensionsInfoDataHasBeenFetched &&
        !dataStatus.someCompaniesDidNotDeliverPensionsInfoData,
    },
    {
      // tax failed, pension data is missing
      errorId: 'Sc307',
      errorPriority: 'lackOfDataAndTax',
      errorCategory: 'calculation',
      hasError:
        triedToGetTaxInfoWithoutSuccess && dataStatus.someCompaniesDidNotDeliverPensionsInfoData,
    },
    {
      // tax failed, pension skipped
      errorId: 'Sc308',
      errorPriority: 'lackOfData',
      errorCategory: 'calculation',
      hasError: triedToGetTaxInfoWithoutSuccess && skippedPension,
    },
    {
      // tax failed, pension failed
      errorId: 'Sc309',
      errorPriority: 'lackOfData',
      errorCategory: 'calculation',
      hasError: triedToGetTaxInfoWithoutSuccess && triedToGetPensionInfoWithoutSuccess,
    },
    {
      // latest try getting tax failed, but old data exists
      // latest try getting pension failed, but old data exists
      errorId: 'Sc310',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'calculation',
      hasError:
        (dataStatus.taxDataHasBeenFetched && dataStatus.isLastTaxDataUpdateFailed) ||
        (dataStatus.pensionsInfoDataHasBeenFetched && dataStatus.isLastPensionInfoUpdateFailed),
      isLastTaxDataUpdateFailed: dataStatus.isLastTaxDataUpdateFailed,
      isLastPensionInfoUpdateFailed: dataStatus.isLastPensionInfoUpdateFailed,
    },
    {
      // tax is outdated and last attempt did not fail
      // pension is oudated and last attempt did not fail
      errorId: 'Sc407',
      errorPriority: 'showDashboardAfterInfo',
      errorCategory: 'calculation',
      hasError:
        (dataStatus.taxDataHasBeenFetched &&
          dataStatus.isTaxDataOutdated &&
          !dataStatus.isLastTaxDataUpdateFailed) ||
        (dataStatus.pensionsInfoDataHasBeenFetched &&
          dataStatus.isPensionsInfoDataOutdated &&
          !dataStatus.isLastPensionInfoUpdateFailed),
      isTaxDataOutdated: dataStatus.isTaxDataOutdated,
      isPensionsInfoDataOutdated: dataStatus.isPensionsInfoDataOutdated,
    },
    {
      // tax and pension fetched and pension is outdated with missing data
      // tax and pension fetched and pension is with missing data and tax is outdated
      /* tax and pension fetched and pension is with missing data and
      both tax and pension is outdated */
      errorId: 'Sc415',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'calculation',
      hasError:
        dataStatus.taxDataHasBeenFetched &&
        dataStatus.someCompaniesDidNotDeliverPensionsInfoData &&
        ((dataStatus.isPensionsInfoDataOutdated && dataStatus.isTaxDataOutdated) ||
          dataStatus.isPensionsInfoDataOutdated ||
          dataStatus.isTaxDataOutdated),
      override: ['Sc407'],
    },
    {
      // no profile found
      errorId: 'Sc411',
      errorPriority: 'dashboardCantBeShown',
      errorCategory: 'calculation',
      hasError: dataStatus.profileNotFound,
    },
    {
      // has active disability coverage
      errorId: 'Sc416',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'calculation',
      hasError: dataStatus.sampensionDataHasBeenFetched && dataStatus.hasActiveDisabilityCoverage,
    },
    {
      // has temporary disability coverage
      errorId: 'Sc417',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'calculation',
      hasError:
        dataStatus.sampensionDataHasBeenFetched && dataStatus.hasActiveTemporaryDisabilityCoverage,
    },
    {
      /* information about ivalidepension, midlertidig invalide pension
      and præmiefritagelse could not be delivered. */
      errorId: 'Sc418',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'calculation',
      hasError: dataStatus.sampensionDataHasBeenFetched === false,
    },
    {
      errorId: 'Sc400',
      errorPriority: 'dashboardCantBeShown',
      errorCategory: 'calculation',
      hasError: error === 'CalculationFailedForAllPensionAges',
    },
    {
      errorId: 'Sc401',
      errorPriority: 'dashboardCantBeShown',
      errorCategory: 'calculation',
      hasError: error === 'InvalidAdditionalAnnualPayoutFromLivsvarigLivrente',
    },
    {
      errorId: 'Sc403',
      errorPriority: 'dontShowDashboard',
      errorCategory: 'calculation',
      hasError: error === 'CannotCalculateForRetiredPerson',
    },
    {
      errorId: 'Sc404',
      errorPriority: 'dontShowDashboard',
      errorCategory: 'calculation',
      hasError: error === 'CannotCalculateForPersonWithTjenestemandsordning',
    },
    {
      errorId: 'Sc405',
      errorPriority: 'dontShowDashboard',
      errorCategory: 'calculation',
      hasError: error === 'CannotPerformDisabilityCalculationForPrimaryPersonPastFolkepensionAge',
    },
    {
      errorId: 'Sc406',
      errorPriority: 'showDashboardAfterInfo',
      errorCategory: 'calculation',
      hasError:
        error === undefined &&
        dataStatus.noPensionAgeFoundWithPayoutSatisfyingRecommendedPensionVsTodayRatio,
    },
    {
      errorId: 'Sc408',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'disposable',
      hasError:
        error ===
          'MedianOfSalarySlipsDifferFromAnnualStatementAndOptionHasNotBeenExplicitlySelected' ||
        error === 'InvalidYearlyIncomeAndValidSalarySlipsAndOptionHasNotBeenExplicitlySelected',
    },
    {
      errorId: 'Sc409',
      errorPriority: 'showDashboardAfterUserAction',
      errorCategory: 'disposable',
      hasError: dataStatus.disposableIncomeBelowRecommendationLimit,
    },
    {
      errorId: 'Sc410',
      errorPriority: 'dontShowDashboard',
      errorCategory: 'calculation',
      hasError: error === 'CannotCalculateManualShotDown',
    },
    {
      errorId: 'Sc412',
      errorPriority: 'dashboardCantBeShown',
      errorCategory: 'calculation',
      hasError: error === 'InvalidDisposableIncome',
    },
    {
      errorId: 'Sc413',
      errorPriority: 'dashboardCantBeShown',
      errorCategory: 'calculation',
      hasError: error === 'PayoutYearContributionSumDifference',
    },
    {
      errorId: 'Sc414',
      errorPriority: 'dashboardCantBeShown',
      errorCategory: 'disposable',
      hasError: error === 'InvalidUserEnteredDisposableIncome',
    },
    {
      errorId: 'Sc420',
      errorPriority: 'dontShowDashboard',
      errorCategory: 'calculation',
      hasError: error === 'CannotCalculateForPersonPastMaxAge',
    },
    {
      errorId: 'Sc421',
      errorPriority: 'showDashboardAfterInfo',
      errorCategory: 'calculation',
      hasError: dataStatus.isMissingChildrenData,
    },
    {
      errorId: 'Sc100',
      errorPriority: 'showDashboardAfterInfo',
      errorCategory: 'calculation',
      hasError: dataStatus.missingYearlyPayoutPrognosis === 'SampensionAndPensionInfo',
    },
    {
      errorId: 'Sc101',
      errorPriority: 'showDashboardAfterInfo',
      errorCategory: 'calculation',
      hasError: dataStatus.missingYearlyPayoutPrognosis === 'PensionInfo',
    },
  ];

  return errorCombos;
};

const errorPriority = {
  partlyDelivered: 10,
  lackOfData: 70,
  lackOfDataAndTax: 50,
  dashboardCantBeShown: 40,
  dontShowDashboard: 60,
  showDashboardAfterInfo: 20,
  showDashboardAfterUserAction: 30,
};

export const getErrorsByHighestPriority = (dataStatus, error) => {
  const errorsByHighestPriority = errorScenarios(dataStatus, error)
    .filter((item) => item.hasError)
    .map((item) => ({
      ...item,
      priority: errorPriority[item.errorPriority] || 0,
    }))
    .sort((a, b) => b.priority - a.priority);

  if (errorsByHighestPriority && errorsByHighestPriority.length > 0) {
    const overrides = [];

    errorsByHighestPriority.forEach((item) => {
      if (item.override) {
        overrides.push(...item.override);
      }
    });

    return errorsByHighestPriority.filter((item) => !overrides.includes(item.errorId));
  }

  return undefined;
};
