import { get, post } from 'actions/api';
import { isAndroid, isWeb } from 'helpers/platform';
import {
  CHANGE_INVESTMENT_CURRENT_PROFILES,
  CHANGE_INVESTMENT_QUESTIONS,
  CHANGE_INVESTMENT_RECOMMENDED_PROFILE,
  CHANGE_INVESTMENT_PENDING_CHANGES,
  CHANGE_INVESTMENT_PROFILE_CONSENTS,
  SET_CHANGE_INVESTMENT_PROFILE_CONTACT_INFO,
  SET_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES,
  CHANGE_INVESTMENT_PROFILE,
  CHANGE_INVESTMENT_COMPLETE_SIGNING,
  CHANGE_INVESTMENT_CLEAR_PENDING_CHANGES,
  SET_CHANGE_INVESTMENT_PROFILE_POLICIES,
  SET_CHANGE_INVESTMENT_PROFILE_ANSWERS,
  CLEAR_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES,
  START_CHANGE_INVESTMENT_PROFILE_FLOW,
} from 'constants/actionTypes';
import * as routeNames from 'constants/routeNames';
import { getHostedUri } from '../areas/deposits/helpers';

export const startChangeInvestmentProfileFlow = () => ({
  type: START_CHANGE_INVESTMENT_PROFILE_FLOW,
});

export const getPendingChanges = () =>
  get(
    CHANGE_INVESTMENT_PENDING_CHANGES,
    'api',
    '/investmentprofile/v1/investmentprofile/changeinvestmentprofilependingrequests'
  );

export const getCurrentInvestmentProfiles = (allowFailure) =>
  get(
    CHANGE_INVESTMENT_CURRENT_PROFILES,
    'api',
    '/investmentprofile/v1/investmentprofile/currentinvestmentprofiles',
    {
      allowFailure,
    }
  );

export const clearPendingChanges = () => ({ type: CHANGE_INVESTMENT_CLEAR_PENDING_CHANGES });

export const getQuestions = (coverageNumbers) =>
  get(
    CHANGE_INVESTMENT_QUESTIONS,
    'api',
    `/investmentprofile/v1/investmentprofile/questions?coverageNumbers=${coverageNumbers?.join(
      '&coverageNumbers='
    )}`,
    {}
  );

export const getRecommendedInvestmentProfile = (data, companyGroup, policies) =>
  post(
    CHANGE_INVESTMENT_RECOMMENDED_PROFILE,
    {
      companyGroup,
      answeredQuestions: data,
      coverageNumbers: policies.map((policy) => policy.coverageNumber),
    },
    'api',
    '/investmentprofile/v1/investmentprofile/recommendedinvestmentprofile'
  );

export const setAnswers = (payload) => ({ type: SET_CHANGE_INVESTMENT_PROFILE_ANSWERS, payload });

export const getConsents = (coverageNumbers) =>
  get(
    CHANGE_INVESTMENT_PROFILE_CONSENTS,
    'api',
    `/investmentprofile/v1/investmentprofile/consents?coverageNumbers=${coverageNumbers?.join(
      '&coverageNumbers='
    )}`
  );

export const setContactInfo = ({ email, phone }) => ({
  type: SET_CHANGE_INVESTMENT_PROFILE_CONTACT_INFO,
  payload: { email, phone },
});

export const setNewRiskProfiles = (riskProfiles) => ({
  type: SET_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES,
  payload: riskProfiles,
});

export const clearNewRiskProfiles = () => ({
  type: CLEAR_CHANGE_INVESTMENT_PROFILE_NEW_RISK_PROFILES,
});

export const setPolicies = (policies) => ({
  type: SET_CHANGE_INVESTMENT_PROFILE_POLICIES,
  payload: policies,
});

const completeUrlWeb = `${global?.window?.location?.origin}/${routeNames.CHANGE_INVESTMENT_PROFILE}/${routeNames.CHANGE_INVESTMENT_PROFILE_SIGNING_SUCCESS}?signed=true`;
const cancelUrlWeb = `${global?.window?.location?.origin}/${routeNames.MORE}`;

const getCancelUrlForNative = (hasMitId) => {
  // if device is android, redirect from mitid after signing is not working.
  // A workaround is to redirect to a page that will redirect to the app
  if (isAndroid && hasMitId) {
    const origin = getHostedUri();

    return `${origin}/redirect?flow=signing&screen=${routeNames.CHANGE_INVESTMENT_PROFILE}&cancelled=true`;
  }

  return `sampension://main/${routeNames.CHANGE_INVESTMENT_PROFILE}/${routeNames.CHANGE_INVESTMENT_PROFILE_SIGNING}?cancelled=true`;
};
const getCompleteUrlForNative = (hasMitId) => {
  // if device is android, redirect from mitid after signing is not working.
  // A workaround is to redirect to a page that will redirect to the app
  if (isAndroid && hasMitId) {
    const origin = getHostedUri();
    return `${origin}/redirect?flow=signing&screen=${routeNames.CHANGE_INVESTMENT_PROFILE}&completed=true`;
  }
  return `sampension://main/${routeNames.CHANGE_INVESTMENT_PROFILE}/${routeNames.CHANGE_INVESTMENT_PROFILE_SIGNING}?completed=true`;
};
export const changeProfile = (changeRequests, contactInformation, answeredQuestions, hasMitId) => {
  const cancelUrl = isWeb ? cancelUrlWeb : getCancelUrlForNative(hasMitId);
  const completeUrl = isWeb ? completeUrlWeb : getCompleteUrlForNative(hasMitId);
  return post(
    CHANGE_INVESTMENT_PROFILE,
    {
      cancelUrl,
      completeUrl,
      changeRequests,
      contactInformation,
      answeredQuestions,
    },
    'api',
    '/investmentprofile/v1/investmentprofile/changeinvestmentprofile',
    {}
  );
};

export const completeSigning = (signingId) =>
  post(
    CHANGE_INVESTMENT_COMPLETE_SIGNING,
    { signingId },
    'api',
    '/investmentprofile/v1/investmentprofile/changeinvestmentprofilehasbeensigned',
    {}
  );
